import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { history } from '_helpers';
import { Nav, PrivateRoute } from '_components';
import { Home } from 'home';
import { Login } from 'login';
import { LoginToken } from "./login/LoginToken";
import { Locked } from "./login/Locked";
import { Layout, theme } from 'antd';
import { Challenge, Challenges } from "./challenge";
import { Success } from "./login/Success";
import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";

const { Header, Content, Footer } = Layout;
export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Layout>
            <Nav />
            <Content style={{ padding: '10px 48px' }}>
                <div
                    style={{
                        background: colorBgContainer,
                        minHeight: '100%',
                        padding: 24,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Routes>
                        <Route
                            path="/"
                            element={
                                // <PrivateRoute>
                                <Home/>
                                // </PrivateRoute>
                            }
                        />
                        <Route path="/challenges" element={<Challenges/>}/>
                        <Route path="/challenge/:link" element={<Challenge/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/success" element={<Success/>}/>
                        <Route path="/locked" element={<Locked/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                        <Route path="/l/:token" element={<LoginToken/>}/>
                    </Routes>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
               CR8 Games ©{new Date().getFullYear()} Created by Georgii Kharlampiiev
            </Footer>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </Layout>
    );
}
