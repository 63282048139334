import { useSelector, useDispatch } from 'react-redux';
import React, {useEffect, useState} from 'react';

import {Form, Input, Button} from 'antd';

import { history } from '_helpers';
import {authActions} from "../_store/auth.slice";

export { Login };

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.profile);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules
    const [componentDisabled, setComponentDisabled] = useState(false);

    function onSubmit({ email }) {
        setComponentDisabled(true)
        return dispatch(authActions.login({ email }));
    }

    return (
        <>
            <title>Login - CR8 Games - Interview preparation game changer!(BETA)</title>
            <Form
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                onFinish={onSubmit}
                autoComplete="off"
                disabled={componentDisabled}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{required: true, message: 'Please input your email!'}, {type: 'email'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
