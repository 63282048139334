import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { authSlice } from '_store';

import {Menu, Layout} from 'antd';
import {authActions} from "../_store/auth.slice";
const { Header} = Layout;
export { Nav };

function Nav() {
    const authUser = useSelector(x => x.auth.profile);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    const loginLogout = (profile) => {
        if (profile) {
            return <NavLink onClick={logout} className="nav-item nav-link">Logout</NavLink>
        }
        return <NavLink  to="/login" className="nav-item nav-link">Login</NavLink>
    }

    return (
        <Header style={{display: 'flex', alignItems: 'center'}}>
            <NavLink to="/" style={{height:"64px"}}><img height="64" alt="logo" src="../img/logo-big.png"/></NavLink>
            <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={['0']}
                items={[
                    {key: 1, label: <NavLink to="/" className="nav-item nav-link">Home</NavLink>},
                    {key: 2, label: <NavLink to="/challenges" className="nav-item nav-link">Challenges</NavLink>},
                    {key: 3, label: <NavLink to="https://discord.gg/PUaHvDguep" className="nav-item nav-link" target="_blank">Discord</NavLink>},
                    {key: 4, label: <NavLink to="https://www.patreon.com/cr8_games" className="nav-item nav-link" target="_blank">Patreon</NavLink>},
                    {key: 10, label: loginLogout(authUser)}
                ]}
                style={{flex: 1, minWidth: 0}}
            />
        </Header>
    );

}