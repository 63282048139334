import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { history, fetchWrapper } from '_helpers';

// create slice

const name = 'auth';

// Create initial state
const initialState = {
    profile: JSON.parse(localStorage.getItem('profile')),
    error: null,
};

// Create extra actions
const baseUrl = `${process.env.REACT_APP_API_URL}/profile`;

export const login = createAsyncThunk(
    `${name}/login`,
    async ({ email }) => await fetchWrapper.post(`${baseUrl}/login`, { email })
);

export const loginToken = createAsyncThunk(
    `${name}/loginToken`,
    async ({ token }) => await fetchWrapper.post(`${baseUrl}/authenticate-by-token`, { token })
);

// Create slice
const slice = createSlice({
    name,
    initialState,
    reducers: {
        logout: (state) => {
            state.profile = null;
            localStorage.removeItem('profile');
            history.navigate('/');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.error = null;
                console.log('login pending');
            })
            .addCase(login.fulfilled, (state, action) => {
                const profile = action.payload;
                console.log(profile)
                if(profile.id) {
                    history.navigate('/success');
                }
                // localStorage.setItem('profile', JSON.stringify(profile));
                // state.profile = profile;
                // const { from } = history.location.state || { from: { pathname: '/' } };
                // history.navigate(from);
            })
            .addCase(login.rejected, (state, action) => {
                console.log('login rejected');
                state.error = action.error;
            })
            .addCase(loginToken.pending, (state) => {
                state.error = null;
                console.log('loginToken pending');
            })
            .addCase(loginToken.fulfilled, (state, action) => {
                const profile = action.payload;
                localStorage.setItem('profile', JSON.stringify(profile));
                state.profile = profile;
            })
            .addCase(loginToken.rejected, (state, action) => {
                console.log('loginToken rejected');
                state.error = action.error;
            });
    },
});

// exports

export const authActions = { ...slice.actions, login, loginToken };
export const authReducer = slice.reducer;
