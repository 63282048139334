import { useSelector, useDispatch } from 'react-redux';
import React, {useEffect} from 'react';

import { challengeSlice, submissionSlice } from '_store';

import {List, Spin} from 'antd';
import {CheckCircleTwoTone, CheckSquareTwoTone} from "@ant-design/icons";

export { Challenges };

function Challenges() {
    const dispatch = useDispatch();
    const {challenges} = useSelector(x => x.challenge);
    const {submissions} = useSelector(x => x.submission);
    const {profile} = useSelector(x => x.auth);
    useEffect(() => {
        if (!challenges){
            dispatch(challengeSlice.challengeActions.getAll());
        }
        if (!submissions && profile){
            dispatch(submissionSlice.submissionActions.getAll());
        }
    },[challenges, submissions]);
    if (!challenges || challenges.loading) {
        return <Spin />
    }
    let mappedSubmissions = Array.isArray(submissions) ? submissions.reduce((acc, submission) => {
        acc[submission.challenge_id] = submission;
        return acc;
    }, {}) : {};
    const data = challenges.map((_) => ({
        key: _.id,
        href: `./challenge/${_.link}`,
        title: _.title,
        featureImage: _.featureImage,
        description: _.description,
        prelude: _.prelude,
        challenge_order: _.challenge_order,
        submission: mappedSubmissions[_.id]
    })).sort((a,b) => a.challenge_order - b.challenge_order);

    return (
        <>
            <title>Challenges - CR8 Games - Interview preparation game changer!(BETA)</title>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    // onChange: (page) => {
                    //     // console.log(page);
                    //     // dispatch(lessonSlice.lessonActions.getAll(page));
                    // },
                    showSizeChanger: false,
                    pageSize: 8,
                    total: challenges.size,
                    // current:3
                }}
                dataSource={data}
                // footer={
                //     <div>
                //         <b>ant design</b> footer part
                //     </div>
                // }
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                        actions={[
                            item.submission && item.submission.status === 'ACTIVE' ? <><CheckCircleTwoTone spin/> Completed</>: <></>
                            // <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                            // <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                            // <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
                        ]}
                        extra={
                            <img
                                width={150}
                                alt="logo"
                                src={item.featureImage || 'img/logo.png'}
                            />
                        }
                    >
                        <List.Item.Meta
                            // avatar={<Avatar src={item.avatar} />}
                            title={<a href={item.href}>{item.title}</a>}
                            description={item.description}
                        />
                        {item.prelude}
                    </List.Item>
                )}
            />
        </>
    );
}
