import { useEffect } from 'react';

import {useDispatch, useSelector} from 'react-redux';

import { history } from '_helpers';
import { authSlice } from '_store';
import {useParams, useSearchParams} from "react-router-dom";
import {Spin, Typography} from "antd";
const { Text} = Typography;

export { LoginToken };

function LoginToken() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.profile);
    const error = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const {token} = useParams();
    if (error) return (<Text type="danger">Token is invalid!</Text>);

    dispatch(authSlice.authActions.loginToken({token}))

    if (authUser) history.navigate(`/`);

    return (<><Spin /></>)
}

