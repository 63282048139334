import { useSelector, useDispatch } from 'react-redux';
import React, {useEffect} from 'react';
import { challengeSlice, submissionSlice } from '_store';

import {Space, Typography, Spin, Checkbox, Alert} from 'antd';
import {useParams} from "react-router-dom";

const { Text, Title } = Typography;

export { Challenge };

function Challenge() {
    const dispatch = useDispatch();
    const {currentChallenge} = useSelector(x => x.challenge);
    const {currentSubmission} = useSelector(x => x.submission);
    const {profile} = useSelector(x => x.auth);
    const { link } = useParams();

    useEffect(() => {
        if (!currentChallenge) {
            dispatch(challengeSlice.challengeActions.getCurrent(link));
        } else if(!currentSubmission && currentChallenge && !currentChallenge.loading) {
            console.log('currentSubmission ' + JSON.stringify(currentSubmission))
            dispatch(submissionSlice.submissionActions.getCurrent(currentChallenge.id));
        }
    }, [currentChallenge, currentSubmission, dispatch, link]);

    if (!currentChallenge || currentChallenge.loading) {
        return <Spin/>;
    }
    console.log(currentChallenge.title)

    function handleChange() {
        dispatch(submissionSlice.submissionActions.setCurrent(currentChallenge.id));
    }

    return (
        <>
            <title>{`${currentChallenge.title} - CR8 Games - Interview preparation game changer!(BETA)`}</title>
            <Space direction="vertical">
                <Title level={2}>{currentChallenge.title}</Title>
                {profile ? (
                    <Checkbox
                        checked={currentSubmission && currentSubmission.status === 'ACTIVE'}
                        onChange={handleChange}
                    >
                        Completed
                    </Checkbox>
                ) : (
                    <Alert message="Please, login to track your progress." type="warning" />
                )}
                <Text type="secondary">{currentChallenge.description}</Text>
                <Text>{currentChallenge.prelude}</Text>
                <Text>
                    <div dangerouslySetInnerHTML={{__html: currentChallenge.content}}/>
                </Text>
            </Space>
        </>
    );
}
