import { configureStore } from '@reduxjs/toolkit';

import * as authSlice from './auth.slice';
import * as challengeSlice from './challenge.slice';
import * as submissionSlice from './submission.slice';

export { authSlice, challengeSlice, submissionSlice };

export const store = configureStore({
    reducer: {
        auth: authSlice.authReducer,
        challenge: challengeSlice.challengeReducer,
        submission: submissionSlice.submissionReducer
    },
});
