import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

// create slice

const name = 'challenge';

// Create initial state
const initialState = {
    challenges: undefined,
    currentChallenge: undefined,
};

// Create extra actions
const baseUrl = `${process.env.REACT_APP_API_URL}/challenge`;

export const getAll = createAsyncThunk(
    `${name}/get`,
    async () => {
        return await fetchWrapper.get(`${baseUrl}`)
    }
);

export const getCurrent = createAsyncThunk(
    `${name}/getCurrent`,
    async (link) => {
        return await fetchWrapper.get(`${baseUrl}/${link}`)
    }
);
// Create slice
const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAll.pending, (state) => {
                state.challenges = { loading: true };
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.challenges = action.payload;
            })
            .addCase(getAll.rejected, (state, action) => {
                state.challenges = { error: action.error };
            })
            .addCase(getCurrent.pending, (state) => {
                state.currentChallenge = { loading: true };
            })
            .addCase(getCurrent.fulfilled, (state, action) => {
                state.currentChallenge = action.payload;
            })
            .addCase(getCurrent.rejected, (state, action) => {
                state.currentChallenge = { error: action.error };
            });
    },
});

// exports

export const challengeActions = { ...slice.actions, getAll, getCurrent };
export const challengeReducer = slice.reducer;
