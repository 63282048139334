import React from 'react';

import {Image, Card, Typography , List, Space, Spin} from 'antd';
import {DiscordOutlined, OrderedListOutlined, VideoCameraAddOutlined} from "@ant-design/icons";
const { Title } = Typography;

export { Home };

function Home() {
    const grades = [
        { grade: 'Selective Questions', icon: <OrderedListOutlined />, description: 'All the questions are carefully chosen and crafted specifically for preparation for real interviews. They are all related to game mechanics and have progression depending on the completion.' },
        { grade: 'Solutions and Explanation', icon: <VideoCameraAddOutlined />, description: 'Studying algorithms from books is good but challenging. Visualization helps to understand it faster. Moreover, our explanation is all in a gaming manner and with examples from the game. In other words, learning happens without breaking the context.' },
        { grade: 'Support and Lessons', icon:<DiscordOutlined />, description: 'In the Discord chat, there are discussion groups; you can always contact the task author and, in general, ask questions about the assignment. Additionally, there is an opportunity to attend lectures that take place online in Discord.' },
    ];

    return (
        <div>
            <title>CR8 Games - Interview preparation game changer!(BETA)</title>
            {/* Banner */}
            <Image src="../img/banner.png" alt="Banner Image" className="banner-image" preview={false}/>

            {/* Grades */}
            <div className="title-container">
                <Title>Why should you join us in Discord?</Title>
            </div>
            <div className="grades-container">
                {grades.map(({grade, icon, description}) => (
                    <Card key={grade} title={grade} className="grade-card">
                        <p className="grade-card-icon">{icon}</p>
                        <p>{description}</p>
                    </Card>
                ))}
            </div>

            {/* YouTube Video */}
            <div className="youtube-container">
                <Title>What is "CR8 Games"?</Title>
                <iframe
                    title="YouTube Video"
                    src="https://www.youtube.com/embed/KUWFsBwESjo"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
}
