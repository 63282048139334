import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

// create slice

const name = 'submission';

// Create initial state
const initialState = {
    submissions: undefined,
    currentSubmission: undefined,
};

// Create extra actions
const baseUrl = `${process.env.REACT_APP_API_URL}/submission`;

export const getAll = createAsyncThunk(
    `${name}/get`,
    async () => {
        return await fetchWrapper.get(`${baseUrl}`)
    }
);

export const getCurrent = createAsyncThunk(
    `${name}/getCurrent`,
    async (id) => {
        return await fetchWrapper.get(`${baseUrl}/${id}`)
    }
);

export const setCurrent = createAsyncThunk(
    `${name}/setCurrent`,
    async (challenge_id ) => await fetchWrapper.post(`${baseUrl}`, { challenge_id })
);
// Create slice
const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAll.pending, (state) => {
                state.submissions = { loading: true };
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.submissions = action.payload;
            })
            .addCase(getAll.rejected, (state, action) => {
                state.submissions = { error: action.error };
            })
            .addCase(getCurrent.pending, (state) => {
                state.currentSubmission = { loading: true };
            })
            .addCase(getCurrent.fulfilled, (state, action) => {
                state.currentSubmission = action.payload? action.payload : {status: 'INACTIVE'};
            })
            .addCase(getCurrent.rejected, (state, action) => {
                state.currentSubmission = { error: action.error };
            })
            .addCase(setCurrent.pending, (state) => {
                state.currentSubmission = { loading: true };
            })
            .addCase(setCurrent.fulfilled, (state, action) => {
                state.currentSubmission = action.payload;
            })
            .addCase(setCurrent.rejected, (state, action) => {
                state.currentSubmission = { error: action.error };
            });
    },
});

// exports

export const submissionActions = { ...slice.actions, getAll, getCurrent, setCurrent };
export const submissionReducer = slice.reducer;
