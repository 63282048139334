import React from 'react';

import { Typography} from 'antd';
const { Text} = Typography;

export { Success };

function Success() {
    return (
        <>
            <Text type="success">Success</Text>
            <p>
                <Text>Please, check your email for login link. (It could be in "spam" folder)</Text>
            </p>
        </>
    )
}
